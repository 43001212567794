import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getArticlesPerFunder() {
    return await axiosInstance
        .get(`tracker/reports/funders/articles`)
        .catch(handleErrors)
};


export async function getFunderArticles(rorId, type) {
    return await axiosInstance
        .get(`tracker/reports/funder/articles?ror=${rorId}&selection=${type}`)
        .catch(handleErrors)
}