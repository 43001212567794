import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { getAgreements } from "../../helpers/statistics";
import { getDeals } from "../../helpers/deals";
import { shortenTitle } from "pubtrack-frontend-library";
import {
    BarChart,
    Card,
    CustomTable,
    DataTable
} from "pubtrack-frontend-library";


const Deals = (props) => {
    const [agreementsTableData, setAgreementsTableData] = useState({});
    const [agreements, setAgreements] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Institition',
                accessor: 'institition',
                sortable: false
            },
            {
                Header: 'Number of articles',
                accessor: 'count',
                sortable: false
            },
        ], []
    );

    const getDataTable = data => {
        if (data) {
            return data.map(item => {
                const dealTitle = item.institition ? shortenTitle(item.institition, 100) : '';
                return {
                    institition: dealTitle,
                    count: item.count,
                    rorid: item.rorid,
                };
            })
        }
        return [];
    };

    const getDealsData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getDeals(offset, itemsPerPage, sortBy, sortDir, searchValue)
            .then(res => ({ data: getDataTable(res.data) }))
    };

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["rorid"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        getAgreements()
            .then(res => setAgreements(res.data));
    }, []);

    useEffect(() => {
        setAgreementsTableData({
            columns: [
                {
                    label: 'week',
                    field: 'week',
                    attributes: {
                        width: 500,
                        'aria-controls': 'DataTable',
                        'aria-label': 'week',
                    },
                },
                {
                    label: 'prioragreement',
                    field: 'prioragreement',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'prioragreement',
                    },
                },
                {
                    label: 'no prioragreement',
                    field: 'noprioragreement',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'noprioragreement',
                    },
                },
            ],
            rows:
                agreements.map(item => {
                    return (
                        {
                            week: item.week,
                            prioragreement: item.prioragreement,
                            noprioragreement: item.noprioragreement,
                        }
                    )
                })
        });
    }, [agreements]);

    useEffect(() => {
        if (selectedMessage) {
            props.history.push(`/deals/${encodeURIComponent(selectedMessage)}`);
        }
    }, [selectedMessage, props.history]);


    return (
        <div className="container">
            <div className="my-5">
                <MDBRow className="mt-5">
                    <MDBCol md='6' className="mb-5 mt-4">
                        <Card
                            title="Prior agreements per week:"
                            content={<BarChart data={agreements} xAxisKey="week" yAxisKey="prioragreement"/>}
                        />
                    </MDBCol>
                    <MDBCol md='6' className="mb-5 mt-4">
                        <Card
                            title="No prior agreements per week:"
                            content={<BarChart data={agreements} xAxisKey="week" yAxisKey="noprioragreement"/>}
                        />
                    </MDBCol>
                </MDBRow>
                <DataTable title="Prior/No prior agreements per week:" data={agreementsTableData} />
            </div>

            <CustomTable
                columns={columns}
                getTableData={getDealsData}
                onSelectRow={onSelectRow}
            />
        </div>
    );
};


export default Deals;