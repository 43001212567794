import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getDeals(offset, perPage, sortBy, sortDir, searchValue) {
    return await axiosInstance
        .get(`tracker/reports/deals?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&issn=${searchValue}`)
        .catch(handleErrors)
};

export async function getDealDetails(selectedMessage) {
    return await axiosInstance
        .get(`tracker/article/${selectedMessage}`)
        .catch(handleErrors)
};
