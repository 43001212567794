import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
    ...theme,

    primaryColor: '#ba0c2f',
    secondaryColor: '#6f263d',

    accordionHeaderBackgroundColor: '#ba0c2f',

    modalHeaderBackgroundColor: '#ba0c2f',

    tabItemColor: '#6f263d',

    navbarBackgroundColor: '#ba0c2f',
    navbarButtonHoverColor: '#ba0c2f',
    logoutButtonBackgroundColor: '#212121',

    paginationButtonBackgroundColor: '#6f263d',
    paginationButtonHoverBackgroundColor: '#6f263d',

    tableHeaderBackgroundColor: '#6f263d',

    searchButtonBackgroundColor: '#6f263d',

    defaultButtonBackgroundColor: '#ba0c2f',
    primaryButtonBackgroundColor: '#6f263d',
    secondaryButtonBackgroundColor: '#ba0c2f',
    colorsPalette: [
        "#ff0000", "#ff2400", "#ed2939", "#cd5c5c", "#e60026", "#960018", "#e0115f", "#dc143c", "#da2c43", "#ce2029"
    ]
}

const App = () => (
    <ThemeProvider theme={customTheme}>
        <GlobalStyles />
        <Routes />
        <Footer />
    </ThemeProvider>
);

export default App;