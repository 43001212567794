export const inPipeLineArticlesColumns = [
    {
        Header: 'Manuscript ID',
        accessor: 'id',
        sortable: false
    },
    {
        Header: 'Title',
        accessor: 'title',
        sortable: false
    },
    {
        Header: 'Journal',
        accessor: 'journal',
        sortable: false
    },
]

export const publishedArticlesColumns = [
    {
        Header: 'DOI',
        accessor: 'doi',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Journal',
        accessor: 'journal'
    }
]

export const articlesPerInstitutionColumns = [
    {
        Header: 'Institution',
        accessor: 'institution',
    },
    {
        Header: 'Count',
        accessor: 'count',
    },
]